class NotificationsManager {
  constructor() {
    this.notifications = [];
    this.keyCounter = 0;
  }

  addNotification(notification) {
    this.keyCounter += 1;
    const notificationToAdd = { ...notification, key: this.keyCounter };
    this.notifications.push(notificationToAdd);
  }

  removeNotification(id) {
    this.notifications = this.notifications.filter(notification => notification.id !== id);
  }
}

export default NotificationsManager;
