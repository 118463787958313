import { deleteAccountRequest, addAccountRequest, updateAccountRequest } from '../requests';
import { authServiceRequest } from './authServiceRequest';

export const UPSERT_ACCOUNT_REQUEST = 'UPSERT_ACCOUNT_REQUEST';
export const UPSERT_ACCOUNT_SUCCESS = 'UPSERT_ACCOUNT_SUCCESS';
export const UPSERT_ACCOUNT_FAILURE = 'UPSERT_ACCOUNT_FAILURE';
export const ACCOUNT_CLEAR_STATE = 'ACCOUNT_CLEAR_STATE';
export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const clearState = {
  type: ACCOUNT_CLEAR_STATE
};

export const addAccount = accountData => {
  return saveAccount(accountData, false, addAccountRequest);
};

export const updateAccount = accountData => {
  return saveAccount(accountData, true, updateAccountRequest);
};

const saveAccount = (accountData, isUpdate, request) => {
  return async (dispatch, getState) => {
    const { session: { jwt } } = getState();
    const resultData = { upsertedAccount: accountData, isUpdate };
    await authServiceRequest({
      dispatch,
      request: request(jwt, accountData),
      actionPending: UPSERT_ACCOUNT_REQUEST,
      actionSuccess: UPSERT_ACCOUNT_SUCCESS,
      appendSuccess: resultData,
      actionFailure: UPSERT_ACCOUNT_FAILURE,
      appendFailure: resultData
    });
  };
};

export const deleteAccount = (accountId, identity) => {
  return async (dispatch, getState) => {
    const { session: { jwt } } = getState();
    await authServiceRequest({
      dispatch,
      request: deleteAccountRequest(jwt, accountId),
      actionPending: DELETE_ACCOUNT_REQUEST,
      actionSuccess: DELETE_ACCOUNT_SUCCESS,
      actionFailure: DELETE_ACCOUNT_FAILURE,
      appendSuccess: { deletedAccountIdentity: identity }
    });
  };
};
