const authBackendUrl = window.APP_CONFIG.authAddress || 'https://auth.api.plan3dev.se';

export async function fetchConfig(jwt) {
  return fetch(`${authBackendUrl}/setup/config`, optionsWithAuthHeaders({ jwt }));
}

export async function getNewsroomsList(jwt) {
  return fetch(`${authBackendUrl}/setup/newsrooms`, optionsWithAuthHeaders({ jwt }));
}

export async function getAccountsInNewsroom(jwt, newsroomId) {
  return fetch(`${authBackendUrl}/setup/accounts?newsroomId=${newsroomId}`, optionsWithAuthHeaders({ jwt }));
}

export async function getAccountsInAdminNewsroom(jwt) {
  return fetch(`${authBackendUrl}/setup/accounts`, optionsWithAuthHeaders({ jwt }));
}

// exclude newsroom param is optional
export async function findIdentities(jwt, identityInfix, excludeNewsroom) {
  let url = `${authBackendUrl}/setup/user-identities?infix=${identityInfix}`;
  if (excludeNewsroom) {
    url += `&excludeNewsroom=${excludeNewsroom}`;
  }
  return fetch(url, optionsWithAuthHeaders({ jwt })).then(resp => (resp.ok ? resp.json() : []));
}

export async function putNewsroom(jwt, newsroomData) {
  const str = [];
  str.push(`id=${newsroomData.id}`);
  str.push(`name=${newsroomData.name}`);
  str.push(`logo=${newsroomData.logo}`);
  str.push(`language=${newsroomData.language}`);
  str.push(`tagcloud=${newsroomData.tagcloud}`);
  str.push(`organization=${newsroomData.organization}`);
  const body = str.join('&');
  return fetch(`${authBackendUrl}/setup/newsrooms`, optionsWithAuthHeaders({ jwt, method: 'PUT', body }));
}

export async function deleteNewsroom(jwt, newsroomId) {
  const body = `id=${newsroomId}`;
  return fetch(`${authBackendUrl}/setup/newsrooms`, optionsWithAuthHeaders({ jwt, method: 'DELETE', body }));
}

export function addAccountRequest(jwt, accountData) {
  const permissions = Object.entries(accountData.userPermissionsMap)
    .filter(([, isEnabled]) => isEnabled)
    .map(([permission]) => permission);
  const body = JSON.stringify({
    identity: accountData.identity,
    newsroomId: accountData.newsroom,
    expirationDate: accountData.expirationDate,
    permissions
  });
  const reqOpts = optionsWithAuthHeaders({ jwt, method: 'POST', body });
  reqOpts['headers']['Content-Type'] = 'application/json';
  return fetch(`${authBackendUrl}/setup/accounts`, reqOpts);
}

export function updateAccountRequest(jwt, accountData) {
  const permissions = Object.entries(accountData.userPermissionsMap)
    .filter(([, isEnabled]) => isEnabled)
    .map(([permission]) => permission);
  const body = JSON.stringify({
    expirationDate: accountData.expirationDate,
    permissions
  });
  const reqOpts = optionsWithAuthHeaders({ jwt, method: 'PUT', body });
  reqOpts['headers']['Content-Type'] = 'application/json';
  return fetch(`${authBackendUrl}/setup/accounts/${accountData.id}`, reqOpts);
}

export async function deleteAccountRequest(jwt, accountId) {
  return fetch(`${authBackendUrl}/setup/accounts/${accountId}`, optionsWithAuthHeaders({ jwt, method: 'DELETE' }));
}

export async function fetchAuditLog(jwt, newsroomId, since) {
  return fetch(
    `${authBackendUrl}/setup/logs?newsroomId=${newsroomId}&since=${since.toISOString()}`,
    optionsWithAuthHeaders({ jwt })
  ).then(resp => (resp.ok ? resp.json() : resp));
}

function optionsWithAuthHeaders({ jwt, method, body }) {
  let options = {
    headers: {
      Authorization: `Plan3JWT ${jwt}`,
      Accept: 'application/json'
    }
  };
  if (method) options = { ...options, method };
  if (body) options = { ...options, body };
  return options;
}
