import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'typeface-roboto';

import App from './screens/App';
import store from './store/createStore';
import { initUserSession, userSessionPromise } from './actions/session';
import { loadConfig } from './actions/config';

store.dispatch(initUserSession());
userSessionPromise.then(() => store.dispatch(loadConfig()));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
