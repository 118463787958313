import * as newsroomActions from '../actions/newsroom';

const defaultState = {
  error: null,
  addedNewsroom: null,
  deletedNewsroom: null,
  successMessage: null,
  errorMessage: null
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case newsroomActions.ADD_NEWSROOM_REQUEST:
      return { ...state };
    case newsroomActions.ADD_NEWSROOM_SUCCESS:
      return {
        ...state,
        addedNewsroom: action.addedNewsroom,
        successMessage: `Newsroom ${action.addedNewsroom.id} successfully created`
      };
    case newsroomActions.ADD_NEWSROOM_FAILURE:
      return {
        ...state,
        errorMessage: `Error adding newsroom: ${action.statusText}`,
        error: { status: action.status, statusText: action.statusText }
      };
    case newsroomActions.DELETE_NEWSROOM_REQUEST:
      return { ...state };
    case newsroomActions.DELETE_NEWSROOM_SUCCESS:
      return {
        ...state,
        deletedNewsroom: action.deletedNewsroom,
        successMessage: `Newsroom ${action.deletedNewsroom.id} successfully deleted`
      };
    case newsroomActions.DELETE_NEWSROOM_FAILURE:
      return {
        ...state,
        errorMessage: `Error deleting newsroom: ${action.statusText}`,
        error: { status: action.status, statusText: action.statusText }
      };
    case newsroomActions.NEWSROOM_CLEAR_STATE:
      return { ...defaultState };
    default:
      return state;
  }
};
