import { makeStyles } from '@mui/styles';
import { Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tooltip } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import TableExtendedHead from './TableExtendedHead';
import { useSelector } from 'react-redux';
import ExpirationDate from '../components/ExpirationDate';
import { formatDate } from '../components/util/dateFormat';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  cell: {},
  cellWithBorder: {
    borderLeft: '1px silver solid'
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'white',
    borderRight: '1px #f1f1f1 solid !important'
  },
  stickyExpired: {
    position: 'sticky',
    left: 0,
    background: '#fafafa',
    color: 'white',
    borderRight: '1px #f1f1f1 solid !important'
  },
  textContainer: {
    display: 'block',
    width: '200px',
    paddingTop: '5px !important',
    paddingBottom: '5px !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
});

function descendingComparator(a, b, orderBy) {
  if (
    orderBy === 'name' ||
    orderBy === 'identity' ||
    orderBy === 'newsroom' ||
    orderBy === 'modifiedBy' ||
    orderBy === 'addedBy'
  ) {
    return b[orderBy].localeCompare(a[orderBy]);
  }
  if (orderBy === 'added' || orderBy === 'lastLogin') {
    const aDate = a[orderBy] ? new Date(a[orderBy]).toISOString() : '';
    const bDate = b[orderBy] ? new Date(b[orderBy]).toISOString() : '';
    return bDate.localeCompare(aDate);
  }
  if (orderBy === 'expirationDate') {
    const aDate = a[orderBy] ? new Date(a[orderBy]).toISOString() : '';
    const bDate = b[orderBy] ? new Date(b[orderBy]).toISOString() : '';
    if (aDate === bDate) return 0;
    if (!aDate) return -1;
    if (!bDate) return 1;
    return bDate.localeCompare(aDate);
  }
  if (orderBy.includes('permissionCell_')) {
    const permission = orderBy.split('_')[1];
    const aPermissionValue = a.permissions.includes(permission) ? 1 : 0;
    const bPermissionValue = b.permissions.includes(permission) ? 1 : 0;
    return aPermissionValue - bPermissionValue;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return (a, b) => {
    const result = order === 'desc' ? descendingComparator(a, b, orderBy) : -descendingComparator(a, b, orderBy);
    if (result !== 0) return result;
    // If primary sorting is the same, use secondary sorting
    return -descendingComparator(a, b, 'identity');
  };
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const AccountsTable = ({ accountsOverview, newsroomsByIds, editAccount }) => {
  const config = useSelector(state => state.config);

  const classes = useStyles();
  const tableContainerRef = useRef(null);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('identity');
  const [page, setPage] = useState(0);
  const rowsPerPage = 1000;

  useEffect(() => {
    const savedOrder = localStorage.getItem('order');
    const savedOrderBy = localStorage.getItem('orderBy');

    if (!areAllNewsroomsSelected && savedOrderBy === 'newsroom') {
      setOrderBy('identity');
    } else if (savedOrderBy) {
      setOrderBy(savedOrderBy);
    }
    if (savedOrder) setOrder(savedOrder);
  }, []);

  useEffect(
    () => {
      localStorage.setItem('order', order);
      localStorage.setItem('orderBy', orderBy);
    },
    [order, orderBy]
  );

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      const scrollPosition = localStorage.getItem('scrollPosition');
      if (scrollPosition !== null) {
        tableContainer.scrollTop = parseInt(scrollPosition, 10);
      }
      const handleScroll = () => {
        localStorage.setItem('scrollPosition', tableContainer.scrollTop);
      };
      tableContainer.addEventListener('scroll', handleScroll);
      return () => {
        tableContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const rows = accountsOverview.accountsGridRows;
  const { permissions, accessPermissions, adminPermissions } = config;
  const { areAllNewsroomsSelected, isPermissionsView } = accountsOverview;

  const numberOfColumns = permissions.length + 2;

  const sortHandler = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    const tableContainer = tableContainerRef.current;
    localStorage.setItem('scrollPosition', 0);
    tableContainer.scrollTop = 0;
    setPage(newPage);
  };

  const newsroomLabel = row => {
    const newsroom = newsroomsByIds[row.newsroom];
    return newsroom ? newsroom.name : row.newsroom;
  };

  const valueWithTooltip = value => (
    <Tooltip title={value} enterDelay={1000}>
      <div> {value} </div>
    </Tooltip>
  );

  return (
    <div style={{ height: 'calc(100vh - 160px)', width: '100%' }}>
      {rows.length > rowsPerPage && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      )}
      <TableContainer ref={tableContainerRef} sx={{ maxHeight: 'calc(100vh - 76px - 48px - 32px - 52px)' }}>
        <Table
          size="small"
          className={classes.table}
          style={{ tableLayout: 'fixed' }}
          stickyHeader={true}
          aria-label="simple table"
          sx={{
            '& .MuiTableCell-sizeSmall': {
              padding: `6px 0px 6px 16px`
            }
          }}
        >
          <TableExtendedHead
            accountsOverview={accountsOverview}
            order={order}
            orderBy={orderBy}
            sortHandler={sortHandler}
          />
          <TableBody>
            {rows.length > 0 ? (
              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  const rowId = 'row_' + row.id;
                  const newsroom = newsroomLabel(row);
                  const { isExpired } = row;
                  return (
                    <TableRow
                      key={rowId}
                      sx={{
                        '&:last-child td, &:last-child th': { borderBottom: 0 },
                        '&:hover td, &:hover th': { backgroundColor: '#f1f1f1', cursor: 'pointer' },
                        '& td, & th': { color: isExpired ? '#bababa' : 'black' },
                        backgroundColor: isExpired ? '#fafafa' : '#ffffff',
                        height: '40px'
                      }}
                      style={{ color: isExpired ? 'f5f5f5' : '#000000' }}
                      onClick={() => editAccount(row.id, row.newsroom)}
                    >
                      <TableCell
                        className={isExpired ? classes.stickyExpired : classes.sticky}
                        component="th"
                        scope="row"
                        key={rowId + '_nameCell'}
                      >
                        {valueWithTooltip(row.name)}
                      </TableCell>
                      <TableCell className={classes.textContainer} key={rowId + '_identityCell'}>
                        {valueWithTooltip(row.identity)}
                      </TableCell>
                      {areAllNewsroomsSelected && (
                        <TableCell className={classes.textContainer} key={rowId + '_newsroomCell'}>
                          {valueWithTooltip(newsroom)}
                        </TableCell>
                      )}
                      {isPermissionsView &&
                        _.flatten([accessPermissions, adminPermissions]).map((permission, index) => {
                          const cellClass =
                            index === 0 || index === accessPermissions.length ? classes.cellWithBorder : classes.cell;
                          return (
                            <TableCell
                              align="center"
                              className={cellClass}
                              key={rowId + '_permissionCell_' + permission.permission}
                            >
                              {row.permissions.includes(permission.permission) && <Box sx={{ fontSize: 10 }}>⬤</Box>}
                            </TableCell>
                          );
                        })}
                      {!isPermissionsView && (
                        <>
                          <TableCell
                            className={classes.cellWithBorder}
                            component="th"
                            scope="row"
                            key={rowId + '_modifiedByCell'}
                          >
                            {valueWithTooltip(row.modifiedBy)}
                          </TableCell>
                          <TableCell
                            className={classes.textContainer}
                            component="th"
                            scope="row"
                            key={rowId + '_addedByCell'}
                          >
                            {valueWithTooltip(row.addedBy)}
                          </TableCell>
                          <TableCell
                            className={classes.textContainer}
                            component="th"
                            scope="row"
                            key={rowId + '_addedCell'}
                          >
                            {valueWithTooltip(formatDate(row.added))}
                          </TableCell>
                          <TableCell
                            className={classes.textContainer}
                            component="th"
                            scope="row"
                            key={rowId + '_lastLoginCell'}
                          >
                            {valueWithTooltip(formatDate(row.lastLogin))}
                          </TableCell>
                          <TableCell
                            className={classes.textContainer}
                            component="th"
                            scope="row"
                            key={rowId + '_expirationDate'}
                          >
                            <ExpirationDate account={row} />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={numberOfColumns}
                  sx={{
                    color: 'gray',
                    justifyContent: 'center',
                    margin: 'auto',
                    textAlign: 'center'
                  }}
                >
                  Nothing to display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default AccountsTable;
