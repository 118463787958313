import * as accountActions from '../actions/account';

const defaultState = {
  error: null,
  upsertedAccount: null,
  deletedAccountIdentity: null,
  successMessage: null,
  errorMessage: null
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case accountActions.UPSERT_ACCOUNT_REQUEST:
      return { ...state };
    case accountActions.UPSERT_ACCOUNT_SUCCESS:
      const msg =
        `Account ${action.upsertedAccount.identity} successfully ` +
        (action.isUpdate ? 'updated' : `added to ${action.upsertedAccount.newsroom} newsroom`);
      return {
        ...state,
        upsertedAccount: action.upsertedAccount,
        successMessage: msg
      };
    case accountActions.UPSERT_ACCOUNT_FAILURE:
      const actionDescription = action.isUpdate ? 'updating' : 'adding';
      return {
        ...state,
        errorMessage: `Error ${actionDescription} account: ${action.statusText}`,
        error: { status: action.status, statusText: action.statusText }
      };
    case accountActions.DELETE_ACCOUNT_REQUEST:
      return { ...state };
    case accountActions.DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deletedAccountIdentity: action.deletedAccountIdentity,
        successMessage: `Account ${action.deletedAccountIdentity} successfully deleted`
      };
    case accountActions.DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        errorMessage: `Error deleting account: ${action.statusText}`,
        error: { status: action.status, statusText: action.statusText }
      };
    case accountActions.ACCOUNT_CLEAR_STATE:
      return { ...defaultState };
    default:
      return state;
  }
};
