export const EXPIRATION_MANAGED_DOMAIN_MSG = 'Expiration dates are optional for accounts with internal email addresses';

export const EXPIRATION_NON_MANAGED_DOMAIN_MSG =
  'Expiration dates are mandatory for accounts with external email addresses';

export const MAX_EXPIRATION_DAYS = 18 * 31; // this value is also set separately in backend, so they should be kept synchronized

export function extractDomain(identity) {
  if (!identity) {
    return identity;
  }
  // a bit naive because email may contain multiple @ characters, but that's very unusual
  return identity.replace(/.*@/, '');
}
