import { GET_NEWSROOMS_REQUEST, GET_NEWSROOMS_SUCCESS, GET_NEWSROOMS_FAILURE } from '../actions/newsroomsList';

export default (
  state = {
    inOrder: [],
    byId: {},
    loading: false,
    error: null
  },
  action
) => {
  switch (action.type) {
    case GET_NEWSROOMS_REQUEST:
      return { ...state, loading: true };
    case GET_NEWSROOMS_SUCCESS:
      return {
        ...state,
        inOrder: action.payload,
        byId: Object.fromEntries(action.payload.map(newsroom => [newsroom.id, newsroom])),
        loading: false
      };
    case GET_NEWSROOMS_FAILURE:
      return {
        ...state,
        error: { status: action.status, statusText: action.statusText },
        loading: false
      };
    default:
      return state;
  }
};
