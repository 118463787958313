import {
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS_FOUND,
  GET_SESSION_SUCCESS_NOT_FOUND,
  GET_SESSION_FAILURE,
  GET_SESSION_NO_PERMISSION
} from '../actions/session';

export default (
  state = {
    authUrl: null,
    jwt: null,
    newsroom: null,
    role: null,
    loading: false,
    error: null,
    isUserSystemAdmin: false,
    logOutMessage: null
  },
  action
) => {
  switch (action.type) {
    case GET_SESSION_REQUEST:
      return { ...state, loading: true, logOutMessage: action.logOutMessage };
    case GET_SESSION_SUCCESS_FOUND:
      return {
        authUrl: action.authUrl,
        jwt: action.jwt,
        newsroom: action.newsroom,
        role: action.role,
        permissions: action.permissions,
        isUserSystemAdmin: action.newsroom === 'plan3',
        loading: false
      };
    case GET_SESSION_SUCCESS_NOT_FOUND:
      return {
        ...state,
        loginUrls: action.loginUrls
      };
    case GET_SESSION_NO_PERMISSION:
      return {
        ...state,
        loginUrls: action.loginUrls,
        missingPermission: action.missingPermission
      };
    case GET_SESSION_FAILURE:
      return {
        ...state,
        error: { status: action.status, statusText: action.statusText },
        loading: false
      };
    default:
      return state;
  }
};
