import React from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';

const styles = () => {
  return {
    loginSelector: {
      width: '100%',
      height: '100%',
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column',
      background: '#f0f0f0',
      zIndex: 5
    },
    innerLoginSelector: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '20px',
      border: '1px silver solid',
      width: '400px',
      display: 'flex',
      flexDirection: 'column',
      background: '#FFF',
      zIndex: 5
    },
    icon: {
      fontSize: '1rem'
    },
    content: {},
    header: {},
    main: {},
    message: {}
  };
};

class LoginSelector extends React.Component {
  render() {
    const popupMessage = !!this.props.userMessage ? (
      <div>
        <div className={this.props.classes.message}>
          <p>
            <Info className={this.props.classes.icon} />
            {this.props.userMessage}
          </p>
        </div>
      </div>
    ) : null;

    if (this.props.loginUrls) {
      return (
        <div className={this.props.classes.loginSelector}>
          {popupMessage}
          <div className={this.props.classes.innerLoginSelector}>
            <Typography variant="h4" style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '15px' }}>
              Account management
            </Typography>
            <Button size={'medium'} color={'primary'} href={this.props.loginUrls.okta}>
              Sign in as Schibsted user
            </Button>
            <Button size={'medium'} color={'primary'} href={this.props.loginUrls.google}>
              Sign in with Google account
            </Button>
            <Button size={'medium'} color={'primary'} href={this.props.loginUrls.azure}>
              Sign in as Polaris user
            </Button>
            <img
              src={'/schibsted-logo.png'}
              height={28}
              width={155}
              style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '25px', marginBottom: '10px' }}
              alt={'Schibsted Logo'}
            />
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  return {
    loginUrls: state.session.loginUrls,
    missingPermission: state.session.missingPermission,
    userMessage: state.session.missingPermission || state.session.logOutMessage
  };
};

export default compose(connect(mapStateToProps), withStyles(styles))(LoginSelector);
