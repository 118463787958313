import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import _ from 'lodash';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#f1f1f1 !important'
  },
  headerWithBorder: {
    backgroundColor: '#f1f1f1 !important',
    borderLeft: '1px silver solid'
  },
  groupHeader: {
    backgroundColor: '#f1f1f1 !important',
    boxSizing: 'border-box'
  },
  stickyHeader: {
    position: 'sticky',
    left: 0,
    zIndex: '500 !important',
    backgroundColor: '#f1f1f1 !important'
  }
});

const TableExtendedHead = props => {
  const config = useSelector(state => state.config);
  const classes = useStyles();

  const { accountsOverview, order, orderBy, sortHandler } = props;
  const { accessPermissions, adminPermissions } = config;
  const { areAllNewsroomsSelected, isPermissionsView } = accountsOverview;

  return (
    <TableHead>
      <TableRow key={'headerGroup'}>
        <TableCell key={'header_nameGroup_1'} width={'220px'} className={classes.stickyHeader} />
        <TableCell key={'header_nameGroup_2'} width={'300px'} className={classes.groupHeader} />
        {areAllNewsroomsSelected && (
          <TableCell key={'header_newsroomGroup'} width={'100px'} className={classes.groupHeader} />
        )}
        {isPermissionsView && (
          <>
            <TableCell
              key={'header_accessGroup'}
              sx={{ borderLeft: '1px silver solid' }}
              width={accessPermissions.length * 100}
              colSpan={accessPermissions.length}
              className={classes.groupHeader}
              align={'center'}
            >
              Access to systems
            </TableCell>
            <TableCell
              key={'header_adminGroup'}
              sx={{ borderLeft: '1px silver solid' }}
              width={adminPermissions.length * 100}
              colSpan={adminPermissions.length}
              className={classes.groupHeader}
              align={'center'}
            >
              Administrative permissions
            </TableCell>
          </>
        )}
        {!isPermissionsView && (
          <>
            <TableCell
              key={'header_detailsGroup'}
              sx={{ borderLeft: '1px silver solid' }}
              width={(accessPermissions.length + adminPermissions.length) * 100}
              colSpan={5}
              className={classes.groupHeader}
              align={'center'}
            >
              Details
            </TableCell>
          </>
        )}
      </TableRow>
      <TableRow key="header" style={{ height: '90px' }}>
        <TableCell key="name" className={classes.stickyHeader}>
          <TableSortLabel
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            onClick={event => sortHandler(event, 'name')}
          >
            Name
            {orderBy === 'name' && (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            )}
          </TableSortLabel>
        </TableCell>
        <TableCell key="identity" className={classes.header}>
          <TableSortLabel
            active={orderBy === 'identity'}
            direction={orderBy === 'identity' ? order : 'asc'}
            onClick={event => sortHandler(event, 'identity')}
          >
            Identity
            {orderBy === 'header_identityCell' && (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            )}
          </TableSortLabel>
        </TableCell>
        {areAllNewsroomsSelected && (
          <TableCell key="newsroom" className={classes.header} width={'100px'}>
            <TableSortLabel
              active={orderBy === 'newsroom'}
              direction={orderBy === 'newsroom' ? order : 'asc'}
              onClick={event => sortHandler(event, 'newsroom')}
            >
              Newsroom
              {orderBy === 'header_newsroom' && (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              )}
            </TableSortLabel>
          </TableCell>
        )}
        {isPermissionsView &&
          _.flatten([accessPermissions, adminPermissions]).map((permission, index) => {
            const cellClass =
              index === 0 || index === accessPermissions.length ? classes.headerWithBorder : classes.header;
            return (
              <TableCell key={'permissionCell_' + permission.permission} className={cellClass} align="center">
                <TableSortLabel
                  active={orderBy === 'permissionCell_' + permission.permission}
                  direction={orderBy === 'permissionCell_' + permission.permission ? order : 'asc'}
                  onClick={event => sortHandler(event, 'permissionCell_' + permission.permission)}
                >
                  {permission.label}
                  {orderBy === 'permissionCell_' + permission.permission && (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  )}
                </TableSortLabel>
              </TableCell>
            );
          })}
        {!isPermissionsView && (
          <>
            <TableCell key="modifiedBy" className={classes.headerWithBorder}>
              <TableSortLabel
                active={orderBy === 'modifiedBy'}
                direction={orderBy === 'modifiedBy' ? order : 'asc'}
                onClick={event => sortHandler(event, 'modifiedBy')}
              >
                Modified by
                {orderBy === 'modifiedBy' && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            </TableCell>
            <TableCell key="addedBy" className={classes.header}>
              <TableSortLabel
                active={orderBy === 'addedBy'}
                direction={orderBy === 'addedBy' ? order : 'asc'}
                onClick={event => sortHandler(event, 'addedBy')}
              >
                Added by
                {orderBy === 'addedBy' && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            </TableCell>
            <TableCell key="added" className={classes.header}>
              <TableSortLabel
                active={orderBy === 'added'}
                direction={orderBy === 'added' ? order : 'asc'}
                onClick={event => sortHandler(event, 'added')}
              >
                Added
                {orderBy === 'added' && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            </TableCell>
            <TableCell key="lastLogin" className={classes.header}>
              <TableSortLabel
                active={orderBy === 'lastLogin'}
                direction={orderBy === 'lastLogin' ? order : 'asc'}
                onClick={event => sortHandler(event, 'lastLogin')}
              >
                Last login
                {orderBy === 'lastLogin' && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            </TableCell>
            <TableCell key="expirationDate" className={classes.header}>
              <TableSortLabel
                active={orderBy === 'expirationDate'}
                direction={orderBy === 'expirationDate' ? order : 'asc'}
                onClick={event => sortHandler(event, 'expirationDate')}
              >
                Expiration date
                {orderBy === 'expirationDate' && (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                )}
              </TableSortLabel>
            </TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableExtendedHead;
