import { getAccountsInAdminNewsroom, getAccountsInNewsroom as getAccountsInNewsroomRequest } from '../requests';
import { authServiceRequest } from './authServiceRequest';
import { reloadNewsrooms } from './newsroomsList';

export const GET_ACCOUNTS_REQUEST = 'GET_ACCOUNTS_REQUEST';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';
export const SELECT_NEWSROOM = 'SELECT_NEWSROOM';
export const CHANGE_ACCOUNTS_SEARCH_QUERY = 'CHANGE_ACCOUNTS_SEARCH_QUERY';
export const TOGGLE_SHOW_EXPIRED_ACCOUNTS = 'TOGGLE_SHOW_EXPIRED_ACCOUNTS';
export const CHANGE_PERMISSIONS_VIEW = 'CHANGE_PERMISSIONS_VIEW';

export const initializeAccountsOverview = () => {
  return async (dispatch, getState) => {
    const { newsroom, isUserSystemAdmin } = getState().session;
    dispatch(selectNewsroom(newsroom));
    dispatch(reloadNewsrooms(isUserSystemAdmin));
  };
};

export const changeAccountsSearchQuery = query => {
  return { type: CHANGE_ACCOUNTS_SEARCH_QUERY, query };
};

export const toggleShowExpiredAccounts = () => {
  return { type: TOGGLE_SHOW_EXPIRED_ACCOUNTS };
};

export const selectNewsroom = newsroomId => {
  return async dispatch => {
    dispatch({ type: SELECT_NEWSROOM, newsroomId, areAllNewsroomsSelected: false });
    dispatch(reloadAccounts());
  };
};

export const changePermissionsView = isPermissionsView => {
  return { type: CHANGE_PERMISSIONS_VIEW, isPermissionsView: isPermissionsView };
};

export const selectAllNewsrooms = () => {
  return async dispatch => {
    dispatch({ type: SELECT_NEWSROOM, newsroomId: null, areAllNewsroomsSelected: true });
    dispatch(reloadAccounts());
  };
};

export const reloadAccounts = () => {
  return async (dispatch, getState) => {
    const { session: { jwt }, accountsOverview: { selectedNewsroomId, areAllNewsroomsSelected } } = getState();
    const request = areAllNewsroomsSelected
      ? getAccountsInAdminNewsroom(jwt)
      : getAccountsInNewsroomRequest(jwt, selectedNewsroomId);
    await authServiceRequest({
      dispatch,
      request,
      actionPending: GET_ACCOUNTS_REQUEST,
      actionSuccess: GET_ACCOUNTS_SUCCESS,
      appendSuccess: { selectedNewsroomId, areAllNewsroomsSelected },
      actionFailure: GET_ACCOUNTS_FAILURE
    });
  };
};
