import { getNewsroomsList } from '../requests';
import { authServiceRequest } from './authServiceRequest';

export const GET_NEWSROOMS_REQUEST = 'GET_NEWSROOMS_REQUEST';
export const GET_NEWSROOMS_SUCCESS = 'GET_NEWSROOMS_SUCCESS';
export const GET_NEWSROOMS_FAILURE = 'GET_NEWSROOMS_FAILURE';

export const reloadNewsrooms = isForSystemAdmin => {
  return async (dispatch, getState) => {
    const { session: { jwt } } = getState();
    await authServiceRequest({
      dispatch,
      request: getNewsroomsList(jwt),
      actionPending: GET_NEWSROOMS_REQUEST,
      actionSuccess: GET_NEWSROOMS_SUCCESS,
      actionFailure: GET_NEWSROOMS_FAILURE
    });
  };
};
