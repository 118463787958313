import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField
} from '@mui/material';

import PropTypes from 'prop-types';
import * as accountActions from '../actions/account';
import { findIdentities } from '../requests';
import ConfirmationDialog from './util/ConfirmationDialog';
import { DateTimePicker } from '@mui/x-date-pickers';
import { createTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { DATE_FORMAT } from './util/dateFormat';
import { extractDomain, MAX_EXPIRATION_DAYS } from './util/expiration';

const defaultTheme = createTheme({});

const styles = () => {
  return {
    formControl: {
      minWidth: 120
    },
    leftIcon: {
      marginRight: 16
    }
  };
};

function calculateExpirationDateBounds() {
  return {
    start: dayjs(),
    end: dayjs().add(MAX_EXPIRATION_DAYS, 'day')
  };
}

const defaultState = props => {
  return {
    newsroom: props.newsroom,
    disableForm: false,
    showValidationErrors: false,
    identityValidationError: null,
    newsroomValidationError: null,
    expirationDateValidationError: null,
    expirationDateBounds: calculateExpirationDateBounds(),
    isDeleteConfirmationOpen: false
  };
};

const initAddModeState = props => {
  const userPermissionsMap = Object.fromEntries(props.allPermissions.map(permission => [permission.permission, false]));
  return {
    ...defaultState(props),
    dialogTitle: 'Add new account',
    addMode: true,
    editMode: false,
    identity: '',
    name: '',
    expirationDate: null,
    userPermissionsMap,
    matchingIdentities: []
  };
};

const initEditModeState = props => {
  const { accountId } = props;
  const account = props.accounts.filter(account => account.id === accountId)[0];
  const userPermissionsMap = Object.fromEntries(
    props.allPermissions.map(permission => [permission.permission, account.permissions.includes(permission.permission)])
  );
  const expirationDate = account.expirationDate ? dayjs(account.expirationDate) : null;
  return {
    ...defaultState(props),
    accountId,
    dialogTitle: 'Edit account',
    addMode: false,
    editMode: true,
    identity: account.user.identity,
    name: account.user.name,
    expirationDate: expirationDate, // for modifications
    previousExpirationDate: expirationDate, // to use to detect changes
    userPermissionsMap
  };
};

class AccountDialog extends React.Component {
  state = (this.props.accountId ? initEditModeState : initAddModeState)(this.props);

  componentDidMount() {
    this.props.dispatch(accountActions.clearState);
    const expirationDateUpdateBoundsInterval = setInterval(() => {
      this.setState({ expirationDateBounds: calculateExpirationDateBounds() }, this.validateForm);
    }, 1000);
    this.setState({ expirationDateUpdateBoundsInterval });
  }

  closeDialog = dataWasChanged => {
    if (this.state.expirationDateUpdateBoundsInterval) {
      clearInterval(this.state.expirationDateUpdateBoundsInterval);
    }
    // parent is responsible for starting and stopping rendering this dialog
    this.props.onClose(dataWasChanged);
  };

  setIsFormDisabled = isDisabled => {
    this.setState({ disableForm: isDisabled });
  };

  togglePermission = permission => {
    const newPermissionsMap = { ...this.state.userPermissionsMap };
    newPermissionsMap[permission] = !this.state.userPermissionsMap[permission];
    this.setState({ userPermissionsMap: newPermissionsMap });
  };

  validateForm = () => {
    const identity = this.state.identity || '';
    const identityDomain = extractDomain(identity);
    const isManagedDomain = this.props.managedDomains.has(identityDomain);
    const isAcceptedDomain = this.props.acceptedDomains.has(identityDomain);

    let identityValidationError = '';
    if (this.state.addMode) {
      if (identity.trim().length === 0) {
        identityValidationError = 'Cannot be empty';
      } else if (identity.search(/@/) === -1) {
        identityValidationError = 'Valid email is required';
      } else if (!isAcceptedDomain) {
        identityValidationError = 'Does not match any allowed domain';
      }
    }

    let newsroomValidationError = '';
    if (!this.state.newsroom) {
      newsroomValidationError = 'Cannot be empty';
    }

    let expirationDateValidationError = '';
    if (!identityValidationError) {
      const mustBeSet = !isManagedDomain;
      const isSet = !!this.state.expirationDate;
      if (mustBeSet && !isSet) {
        expirationDateValidationError = 'Mandatory for accounts with external email addresses';
      } else if (isSet) {
        if (!this.state.expirationDate.isValid()) {
          expirationDateValidationError = 'Invalid or incomplete date';
        } else {
          if (this.state.expirationDate.isSame(this.state.expirationDateBounds.start, 'minute')) {
            expirationDateValidationError = 'Should be set to future value';
          } else if (this.state.expirationDate.isBefore(this.state.expirationDateBounds.start)) {
            if (this.state.editMode && this.state.expirationDate.isSame(this.state.previousExpirationDate)) {
              // we allow to keep date in past if it's unchanged from previous version
            } else {
              expirationDateValidationError = 'Cannot be changed to past value';
            }
          } else if (this.state.expirationDate.isAfter(this.state.expirationDateBounds.end)) {
            expirationDateValidationError = 'Value too far in the future';
          }
        }
      }
    }

    if (this.state.showValidationErrors) {
      // only show validation errors if user has changed the form or is trying to submit
      this.setState({ identityValidationError, newsroomValidationError, expirationDateValidationError });
    }

    return !identityValidationError && !newsroomValidationError && !expirationDateValidationError;
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ showValidationErrors: true }, () => {
      if (!this.validateForm()) {
        return;
      }
      this.setIsFormDisabled(true);
      const accountData = {
        id: this.state.accountId,
        identity: this.state.identity,
        newsroom: this.state.newsroom,
        expirationDate: this.state.expirationDate ? this.state.expirationDate.format() : null,
        userPermissionsMap: this.state.userPermissionsMap
      };
      if (this.state.editMode) {
        this.props.dispatch(accountActions.updateAccount(accountData));
      } else {
        this.props.dispatch(accountActions.addAccount(accountData));
      }
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.account.successMessage) {
      this.props.dispatch(accountActions.clearState);
      this.props.notify({ message: this.props.account.successMessage, severity: 'success' });
      this.closeDialog(true);
      return;
    }
    if (this.props.account.errorMessage) {
      this.props.dispatch(accountActions.clearState);
      this.props.notify({ message: this.props.account.errorMessage, severity: 'error' });
      this.setIsFormDisabled(false);
      return;
    }
  }

  searchIdentities(infix) {
    findIdentities(this.props.session.jwt, infix, this.state.newsroom).then(ids => {
      this.setState({
        matchingIdentities: ids.map(id => {
          return { inputValue: id, title: id };
        })
      });
    });
  }

  filterIdentityOptions = (options, params) => {
    if (params.inputValue && params.inputValue.trim().length > 0) {
      options.unshift({
        inputValue: params.inputValue,
        title: `${params.inputValue}`
      });
    }
    return options;
  };

  handleIdentityChange = (event, value) => {
    this.setState(
      {
        identity: value ? value.inputValue : null,
        showValidationErrors: true
      },
      this.validateForm
    );
  };

  handleIdentityInputChange = event => {
    const value = event.target.value || '';
    const newState = {
      identity: value,
      showValidationErrors: true
    };
    // handle values typed in autocomplete component
    if (event.target.id === 'identity') {
      if (value.trim().length > 3) {
        this.searchIdentities(value);
      } else {
        this.setState({ matchingIdentities: [] });
      }
    }
    this.setState(newState, this.validateForm);
  };

  handleNewsroomSelectorChange = (event, newsroom) => {
    this.setState(
      {
        newsroom: newsroom ? newsroom.id : null,
        showValidationErrors: true
      },
      this.validateForm
    );
  };

  setDeleteConfirmationOpen = isOpen => {
    this.setState({ isDeleteConfirmationOpen: isOpen });
  };

  handleConfirmedDelete = () => {
    this.setDeleteConfirmationOpen(false);
    this.setIsFormDisabled(true);
    this.props.dispatch(accountActions.deleteAccount(this.state.accountId, this.state.identity));
  };

  handleExpirationDateChange = date => {
    this.setState(
      {
        expirationDate: date,
        showValidationErrors: true
      },
      this.validateForm
    );
  };

  render() {
    return (
      <Dialog open={true} fullWidth maxWidth="sm" onClose={() => this.closeDialog(false)} disableRestoreFocus>
        <DialogTitle>{this.state.dialogTitle}</DialogTitle>

        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
          <fieldset disabled={this.state.disableForm} style={{ borderWidth: '0px' }}>
            <DialogContent sx={{ flexDirection: 'column', display: 'flex' }}>
              {this.renderIdentityControl()}
              {this.renderNewsroomControl()}
              {this.renderExpirationDatePicker()}
              {this.renderPermissions('Access to systems', this.props.accessPermissions)}
              {this.renderPermissions('Administrative permissions', this.props.adminPermissions)}
            </DialogContent>

            <ConfirmationDialog
              open={this.state.isDeleteConfirmationOpen}
              handleConfirm={() => this.handleConfirmedDelete()}
              handleReject={() => this.setDeleteConfirmationOpen(false)}
              confirmColorOverride="error"
              dialogTitle="Delete account"
            >
              Are you sure you want to <b>delete</b> account? <br /> <br />
              <b> Name: </b> {this.state.name} <br />
              <b> Identity: </b> {this.state.identity} <br />
            </ConfirmationDialog>

            <DialogActions>
              {this.state.editMode ? (
                <Button
                  variant="outlined"
                  startIcon={<Delete />}
                  color="error" // error color from theme looks better than warning
                  disabled={this.state.disableForm}
                  onClick={() => this.setDeleteConfirmationOpen(true)}
                >
                  Delete account
                </Button>
              ) : (
                <></>
              )}

              <div style={{ flex: '1 0 0' }} />

              <Button
                onClick={() => this.closeDialog(false)}
                disabled={this.state.disableForm}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>

              <Button type="submit" disabled={this.state.disableForm} variant="contained" color="primary">
                {this.state.addMode ? 'Add account' : 'Save changes'}
              </Button>
            </DialogActions>
          </fieldset>
        </form>
      </Dialog>
    );
  }

  renderPermissions(groupLabel, permissions) {
    return (
      <FormControl component="fieldset" margin="normal">
        <FormLabel component="label" focused={false}>
          {groupLabel}
        </FormLabel>
        <FormGroup>
          {permissions.map(permission => {
            return (
              <FormControlLabel
                label={permission.label}
                key={permission.permission}
                control={
                  <Checkbox
                    disabled={this.state.disableForm}
                    checked={this.state.userPermissionsMap[permission.permission]}
                    onClick={() => this.togglePermission(permission.permission)}
                  />
                }
              />
            );
          })}
        </FormGroup>
      </FormControl>
    );
  }

  renderIdentityControl() {
    const { formControl } = styles;
    if (this.state.editMode) {
      return (
        <TextField
          disabled
          className={formControl}
          id="identity"
          label="Identity"
          margin="normal"
          value={this.state.identity}
        />
      );
    }
    return (
      <Autocomplete
        id="identity"
        freeSolo
        autoHighlight
        filterOptions={this.filterIdentityOptions}
        getOptionLabel={option => option.inputValue || ''}
        options={this.state.matchingIdentities}
        onChange={this.handleIdentityChange}
        onInputChange={this.handleIdentityInputChange}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        renderInput={params => (
          <TextField
            label="Identity"
            {...params}
            required
            error={!!this.state.identityValidationError}
            helperText={this.state.identityValidationError}
            margin="normal"
          />
        )}
      />
    );
  }

  renderNewsroomControl() {
    const { formControl } = styles;
    const value = this.state.newsroom ? this.props.newsrooms.byId[this.state.newsroom] : undefined;
    const disableControl = this.state.editMode || this.state.disableForm;

    return (
      <Autocomplete
        disabled={disableControl}
        value={value}
        disablePortal
        options={this.props.newsrooms.inOrder}
        className={formControl}
        style={{ marginTop: '10px' }}
        getOptionLabel={item => item.name}
        renderInput={params => (
          <TextField
            {...params}
            label="Newsroom"
            required
            error={!!this.state.newsroomValidationError}
            helperText={this.state.newsroomValidationError}
            margin="normal"
          />
        )}
        onChange={this.handleNewsroomSelectorChange}
      />
    );
  }

  renderExpirationDatePicker() {
    const toolTip =
      'Expiration dates are mandatory for accounts with external e-mail addresses\n' +
      'and optional for accounts with internal e-mail addresses';

    return (
      <DateTimePicker
        label="Expiration date"
        value={this.state.expirationDate}
        format={DATE_FORMAT}
        closeOnSelect={false}
        disablePast={true}
        skipDisabled={true}
        minDateTime={this.state.expirationDateBounds.start}
        maxDateTime={this.state.expirationDateBounds.end}
        displayWeekNumber={false}
        ampm={false}
        clearable={true}
        onChange={this.handleExpirationDateChange}
        style={{ marginTop: '10px' }}
        slotProps={{
          field: {
            clearable: true
          },
          textField: {
            sx: {
              marginTop: '20px'
            },
            error: !!this.state.expirationDateValidationError,
            helperText: this.state.expirationDateValidationError,
            title: toolTip
          },
          actionBar: {
            actions: ['cancel', 'accept']
          },
          popper: {
            sx: {
              // we disable shadows globally to get a more "flat" look
              // but here we need them for the dialog borders to be visible
              '.MuiPaper-root': { boxShadow: defaultTheme.shadows[5] }
            }
          }
        }}
      />
    );
  }
}

AccountDialog.propTypes = {
  accountId: PropTypes.string, // if account id is provided this dialog will be for editing account, otherwise adding account
  newsroom: PropTypes.string,
  onClose: PropTypes.func, // will be called with (dataWasChanged) param
  notify: PropTypes.func
};

const mapStateToProps = state => {
  return {
    session: state.session,
    newsrooms: state.newsroomsList,
    account: state.account,
    accounts: state.accountsOverview.allAccounts,
    allPermissions: state.config.permissions,
    accessPermissions: state.config.accessPermissions,
    adminPermissions: state.config.adminPermissions,
    managedDomains: state.config.managedDomains,
    acceptedDomains: state.config.acceptedDomains
  };
};

export default compose(withStyles(styles), connect(mapStateToProps))(AccountDialog);
