import React from 'react';
import { Tooltip } from '@mui/material';
import { formatDate } from './util/dateFormat';
import { useSelector } from 'react-redux';
import { EXPIRATION_MANAGED_DOMAIN_MSG, EXPIRATION_NON_MANAGED_DOMAIN_MSG } from './util/expiration';

function dateWithTooltip(value, tooltip) {
  return (
    <Tooltip title={tooltip} placement="top" enterDelay={1000}>
      <div>{value}</div>
    </Tooltip>
  );
}

const ExpirationDate = ({ account }) => {
  const managedDomains = useSelector(state => state.config.managedDomains);
  const isManaged = managedDomains.has(account.identityDomain);
  const label = formatDate(account.expirationDate);
  const tooltip = isManaged ? EXPIRATION_MANAGED_DOMAIN_MSG : EXPIRATION_NON_MANAGED_DOMAIN_MSG;
  return dateWithTooltip(label, tooltip);
};

export default ExpirationDate;
