import React from 'react';
import Alert from './Alert';
import { Portal } from '@mui/material';

const defaultSeverity = 'info';

class Notifications extends React.Component {
  render() {
    return <Portal>{this.props.notifications.map(this.renderNotification)}</Portal>;
  }

  onClose = notification => {
    this.props.onClose(notification.id);
  };

  renderNotification = notification => {
    return (
      <Alert
        key={notification.key}
        message={notification.message}
        onCloseHandler={() => this.onClose(notification)}
        severity={notification.severity || defaultSeverity}
      />
    );
  };
}

export default Notifications;
