export const GET_SESSION_REQUEST = 'GET_SESSION_REQUEST';
export const GET_SESSION_SUCCESS_FOUND = 'GET_SESSION_SUCCESS_FOUND';
export const GET_SESSION_SUCCESS_NOT_FOUND = 'GET_SESSION_SUCCESS_NOT_FOUND';
export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE';
export const GET_SESSION_NO_PERMISSION = 'GET_SESSION_NO_PERMISSION';

// the names here have double meanings sometimes so just to be clear:
// user session is stored on server (managed by express module and using redis)
// however we also use browser sessionStorage object keep some info between page reloads

const LOGOUT_REASON_KEY = 'logoutReason';

var userSessionResolve;
export const userSessionPromise = new Promise(function(resolve) {
  userSessionResolve = resolve;
});

export const logOut = message => {
  if (message) {
    sessionStorage.setItem(LOGOUT_REASON_KEY, message);
  }
  window.location.href = (window.APP_CONFIG.appDomain || 'http://localhost:3001') + '/logout';
};

export const getLogOutMessage = () => {
  const result = sessionStorage.getItem(LOGOUT_REASON_KEY);
  sessionStorage.removeItem(LOGOUT_REASON_KEY);
  return result;
};

export const initUserSession = () => async dispatch => {
  dispatch({ type: GET_SESSION_REQUEST, logOutMessage: getLogOutMessage() });

  const response = await fetch('/checkSession', { credentials: 'include' });

  if (response.status === 200) {
    const json = await response.json();
    const userInfo = json.userInfo;
    if (userInfo) {
      const permissions = userInfo.permissions || [];
      const userHasPermission = permissions.includes('account:admin');
      checkPermission(userHasPermission, userInfo, json);
    } else {
      dispatch({
        type: GET_SESSION_SUCCESS_NOT_FOUND,
        loginUrls: json.loginUrls
      });
    }
  } else {
    dispatch({
      type: GET_SESSION_FAILURE,
      status: response.status,
      statusText: response.statusText
    });
  }

  function checkPermission(userHasPermission, userInfo, json) {
    if (userHasPermission) {
      dispatch({
        type: GET_SESSION_SUCCESS_FOUND,
        ...userInfo
      });
      userSessionResolve(userInfo);
    } else {
      dispatch({
        type: GET_SESSION_NO_PERMISSION,
        loginUrls: json.loginUrls,
        missingPermission: true
      });
    }
  }
};
