import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ConfirmationDialog = props => {
  return (
    <Dialog open={props.open} onClose={props.handleReject} disableRestoreFocus>
      <DialogTitle>{props.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleReject} variant="contained" color="primary" autoFocus>
          Cancel
        </Button>
        <Button onClick={props.handleConfirm} color={props.confirmColorOverride || 'secondary'} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  confirmColorOverride: PropTypes.string,
  dialogTitle: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func,
  handleReject: PropTypes.func
};

ConfirmationDialog.defaultProps = {
  open: false
};

export default ConfirmationDialog;
