import { putNewsroom, deleteNewsroom as deleteNewroomRequet } from '../requests';
import { authServiceRequest } from './authServiceRequest';

export const ADD_NEWSROOM_REQUEST = 'ADD_NEWSROOM_REQUEST';
export const ADD_NEWSROOM_SUCCESS = 'ADD_NEWSROOM_SUCCESS';
export const ADD_NEWSROOM_FAILURE = 'ADD_NEWSROOM_FAILURE';
export const NEWSROOM_CLEAR_STATE = 'NEWSROOM_CLEAR_STATE';
export const DELETE_NEWSROOM_REQUEST = 'DELETE_NEWSROOM_REQUEST';
export const DELETE_NEWSROOM_SUCCESS = 'DELETE_NEWSROOM_SUCCESS';
export const DELETE_NEWSROOM_FAILURE = 'DELETE_NEWSROOM_FAILURE';

export const clearState = {
  type: NEWSROOM_CLEAR_STATE
};

export const addNewsroom = newsroomData => {
  return async (dispatch, getState) => {
    const { session: { jwt } } = getState();
    const addingNewsroom = { addedNewsroom: newsroomData };
    await authServiceRequest({
      dispatch,
      request: putNewsroom(jwt, newsroomData),
      actionPending: ADD_NEWSROOM_REQUEST,
      actionSuccess: ADD_NEWSROOM_SUCCESS,
      actionFailure: ADD_NEWSROOM_FAILURE,
      appendSuccess: addingNewsroom,
      appendFailure: addingNewsroom
    });
  };
};

export const deleteNewsroom = newsroomId => {
  return async (dispatch, getState) => {
    const { session: { jwt } } = getState();
    const deletingNewsroom = { deletedNewsroom: { id: newsroomId } };
    await authServiceRequest({
      dispatch,
      request: deleteNewroomRequet(jwt, newsroomId),
      actionPending: DELETE_NEWSROOM_REQUEST,
      actionSuccess: DELETE_NEWSROOM_SUCCESS,
      actionFailure: DELETE_NEWSROOM_FAILURE,
      appendSuccess: deletingNewsroom,
      appendFailure: deletingNewsroom
    });
  };
};
