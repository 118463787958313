import { logOut } from './session';

export const authServiceRequest = async ({
  dispatch,
  request,
  actionPending,
  appendPending = {},
  actionSuccess,
  appendSuccess = {},
  actionFailure,
  appendFailure = {}
}) => {
  dispatch({ type: actionPending, ...appendPending });

  try {
    const response = await request;
    const payload = await getPayload(response);
    if (response.ok) {
      dispatch({
        type: actionSuccess,
        payload,
        ...appendSuccess
      });
    } else {
      let statusText = payload.errors ? payload.errors.toString() : payload.userMessage || response.statusText;
      if (response.status === 403 && !statusText) {
        statusText = 'You have no permission to perform this action';
      }
      if (response.status === 401) {
        logOut('You have no access to this newsroom or it has expired');
        return;
      }
      statusText = statusText || 'Unexpected error';
      dispatch({
        type: actionFailure,
        status: response.status,
        statusText: statusText,
        ...appendFailure
      });
    }
  } catch (e) {
    dispatch({
      type: actionFailure,
      status: '',
      statusText: e.message,
      ...appendFailure
    });
  }
};

async function getPayload(response) {
  try {
    return await response.json();
  } catch (e) {
    return null;
  }
}
