import { FETCH_CONFIG_FAILURE, FETCH_CONFIG_REQUEST, FETCH_CONFIG_SUCCESS } from '../actions/config';

const defaultState = {
  isLoading: false,
  isLoaded: false,
  isError: false,
  permissions: [],
  managedDomains: new Set(),
  acceptedDomains: new Set()
};

const ADMINISTRATIVE_PERMISSIONS = [
  'newsroom:configure',
  'slug:edit',
  'image:delete',
  'auth:access',
  'curate:settings',
  'account:admin'
];

export default (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_CONFIG_REQUEST:
      return { ...defaultState, isLoading: true };
    case FETCH_CONFIG_FAILURE:
      return { ...defaultState, isError: true };
    case FETCH_CONFIG_SUCCESS:
      const permissions = action.payload.permissions.map(permission => ({
        ...permission,
        label: permission.label
      }));
      const managedDomains = new Set(action.payload.managedDomains);
      const acceptedDomains = new Set(action.payload.managedDomains.concat(action.payload.nonManagedDomains));
      return {
        ...defaultState,
        isLoading: false,
        isLoaded: true,
        isError: false,
        permissions: action.payload.permissions,
        accessPermissions: permissions.filter(
          permission => !ADMINISTRATIVE_PERMISSIONS.includes(permission.permission)
        ),
        adminPermissions: permissions.filter(permission => ADMINISTRATIVE_PERMISSIONS.includes(permission.permission)),
        managedDomains,
        acceptedDomains
      };
    default:
      return state;
  }
};
