import { fetchConfig } from '../requests';
import { authServiceRequest } from './authServiceRequest';

export const FETCH_CONFIG_REQUEST = 'FETCH_CONFIG_REQUEST';
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS';
export const FETCH_CONFIG_FAILURE = 'FETCH_CONFIG_FAILURE';

export const loadConfig = () => {
  return async (dispatch, getState) => {
    const { jwt } = getState().session;
    await authServiceRequest({
      dispatch,
      request: fetchConfig(jwt),
      actionPending: FETCH_CONFIG_REQUEST,
      actionSuccess: FETCH_CONFIG_SUCCESS,
      actionFailure: FETCH_CONFIG_FAILURE
    });
  };
};
