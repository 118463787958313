import React from 'react';
import { AppBar, Box, Button, Menu, MenuItem, Toolbar, Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { MoreVert } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { logOut } from '../actions/session';

const buttonsDimension = 35;

const styles = () => {
  return {
    button: {
      margin: 'auto',
      'min-width': buttonsDimension,
      'min-height': buttonsDimension
    }
  };
};

class Header extends React.Component {
  state = {
    menuButtonElement: null
  };

  addNewsroom = () => {
    this.closeMenu();
    this.props.addNewsroom();
  };

  showAuditLog = () => {
    this.closeMenu();
    this.props.showAuditLog();
  };

  openMenu = event => {
    this.setState({ menuButtonElement: event.currentTarget });
  };

  closeMenu = () => {
    this.setState({ menuButtonElement: null });
  };

  render() {
    return (
      <AppBar className={this.props.classes.header} sx={{ bgcolor: 'transparent' }}>
        <Toolbar>
          <Tooltip title="logout">
            <Button style={{ marginLeft: 'auto' }} size="medium" variant="outlined" onClick={() => logOut()}>
              Log out
            </Button>
          </Tooltip>

          <Box>
            <Button
              className={this.props.classes.button}
              style={{ marginLeft: '10px' }}
              onClick={this.openMenu}
              size="medium"
              variant="text"
            >
              <MoreVert />
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={this.state.menuButtonElement}
              keepMounted
              open={this.state.menuButtonElement !== null}
              onClose={this.closeMenu}
              disableRestoreFocus
            >
              {this.props.isUserSystemAdmin && <MenuItem onClick={this.addNewsroom}>Add newsroom</MenuItem>}
              <MenuItem disabled={this.props.areAllNewsroomsSelected} onClick={this.showAuditLog}>
                Show audit log
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  addNewsroom: PropTypes.func,
  showAuditLog: PropTypes.func
};

const mapStateToProps = state => {
  return {
    isUserSystemAdmin: state.session.isUserSystemAdmin,
    areAllNewsroomsSelected: state.accountsOverview.areAllNewsroomsSelected,
    selectedNewsroomId: state.accountsOverview.selectedNewsroomId,
    session: state.session
  };
};

export default compose(withStyles(styles), connect(mapStateToProps))(Header);
