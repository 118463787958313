import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import * as newsroomActions from '../actions/newsroom';
import PropTypes from 'prop-types';

const styles = () => {
  return {
    formControl: {
      minWidth: 120
    },
    leftIcon: {
      marginRight: 16
    }
  };
};

class AddNewsroomDialog extends React.Component {
  state = {
    name: null,
    id: null,
    language: '',
    tagcloud: '',
    logo: null,
    organization: '',
    nameValidationError: null,
    idValidationError: null,
    disableForm: false
  };

  componentDidMount() {
    this.props.dispatch(newsroomActions.clearState);
  }

  handleChange = event => {
    const identifier = event.target.id || event.target.name;
    const newState = {
      [identifier]: event.target.value,
      [`${identifier}ValidationError`]: !event.target.value
    };
    this.setState(newState);
  };

  closeDialog = dataWasChanged => {
    // parent is responsible for starting and stopping rendering this dialog
    this.props.onClose(dataWasChanged);
  };

  setIsFormDisabled = isDisabled => {
    this.setState({ disableForm: isDisabled });
  };

  handleSubmit = event => {
    event.preventDefault();
    const validationErrors = {
      nameValidationError: !this.state.name,
      idValidationError: !this.state.id,
      languageValidationError: !this.state.language,
      tagcloudValidationError: !this.state.tagcloud,
      organizationValidationError: !this.state.organization
    };
    this.setState(validationErrors);
    const includesErrors = Object.values(validationErrors).includes(true);
    if (!includesErrors) {
      this.setIsFormDisabled(true);
      this.props.dispatch(
        newsroomActions.addNewsroom({
          id: this.state.id,
          name: this.state.name,
          logo: this.state.logo,
          tagcloud: this.state.tagcloud,
          language: this.state.language,
          organization: this.state.organization
        })
      );
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.newsroom.successMessage) {
      this.props.dispatch(newsroomActions.clearState);
      this.props.notify({ message: this.props.newsroom.successMessage, severity: 'success' });
      this.closeDialog(true);
      return;
    }
    if (this.props.newsroom.errorMessage) {
      this.props.dispatch(newsroomActions.clearState);
      this.props.notify({ message: this.props.newsroom.errorMessage, severity: 'error' });
      this.setIsFormDisabled(false);
      return;
    }
  }

  render() {
    const { formControl } = this.props.classes;
    return (
      <Dialog open={true} fullWidth maxWidth="sm" onClose={() => this.closeDialog(false)} disableRestoreFocus>
        <DialogTitle>Add newsroom</DialogTitle>

        <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
          <fieldset disabled={this.state.disableForm} style={{ borderWidth: '0px' }}>
            <DialogContent sx={{ flexDirection: 'column', display: 'flex' }}>
              <TextField
                required
                error={this.state.idValidationError}
                id="id"
                label="Newsroom id"
                margin="normal"
                onChange={this.handleChange}
              />

              <TextField
                required
                error={this.state.nameValidationError}
                id="name"
                label="Newsroom full name"
                margin="normal"
                onChange={this.handleChange}
              />

              <TextField id="logo" label="Url to newsroom logo" margin="normal" onChange={this.handleChange} />

              <FormControl className={formControl} error={this.state.languageValidationError}>
                <InputLabel htmlFor="language">{this.state.language ? '' : 'Language*'}</InputLabel>
                <Select
                  value={this.state.language}
                  onChange={this.handleChange}
                  name="language"
                  disabled={this.state.disableForm}
                >
                  <MenuItem value={'en'}>English</MenuItem>
                  <MenuItem value={'nn'}>Norwegian Nynorsk</MenuItem>
                  <MenuItem value={'nb'}>Norwegian Bokmål</MenuItem>
                  <MenuItem value={'sv'}>Swedish</MenuItem>
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>

              <FormControl className={formControl} error={this.state.tagcloudValidationError}>
                <InputLabel htmlFor="tagcloud">{this.state.tagcloud ? '' : 'Tag Cloud*'}</InputLabel>
                <Select
                  value={this.state.tagcloud}
                  onChange={this.handleChange}
                  name="tagcloud"
                  disabled={this.state.disableForm}
                >
                  <MenuItem value={'sv'}>Swedish</MenuItem>
                  <MenuItem value={'no'}>Norwegian</MenuItem>
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>

              <FormControl className={formControl} error={this.state.organizationValidationError}>
                <InputLabel htmlFor="organization">{this.state.organization ? '' : 'Organization*'}</InputLabel>
                <Select
                  value={this.state.organization}
                  onChange={this.handleChange}
                  name="organization"
                  disabled={this.state.disableForm}
                >
                  <MenuItem value={'polaris'}>Polaris</MenuItem>
                  <MenuItem value={'schibsted'}>Schibsted</MenuItem>
                </Select>
                <FormHelperText>Required</FormHelperText>
              </FormControl>
            </DialogContent>

            <DialogActions sx={{ justifyContent: 'center' }}>
              <Button
                onClick={() => this.closeDialog(false)}
                disabled={this.state.disableForm}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>

              <div style={{ flex: '1 0 0' }} />

              <Button type="submit" disabled={this.state.disableForm} variant="contained" color="primary">
                Add newsroom
              </Button>
            </DialogActions>
          </fieldset>
        </form>
      </Dialog>
    );
  }
}

AddNewsroomDialog.propTypes = {
  onClose: PropTypes.func, // will be called with (dataWasChanged) param
  notify: PropTypes.func
};

const mapStateToProps = state => {
  return { newsroom: state.newsroom };
};

export default compose(withStyles(styles), connect(mapStateToProps))(AddNewsroomDialog);
