import {
  CHANGE_ACCOUNTS_SEARCH_QUERY,
  CHANGE_PERMISSIONS_VIEW,
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  SELECT_NEWSROOM,
  TOGGLE_SHOW_EXPIRED_ACCOUNTS
} from '../actions/accountsOverview';
import { extractDomain } from '../components/util/expiration';

const defaultState = {
  selectedNewsroomId: null,
  areAllNewsroomsSelected: false,
  isPermissionsView: true,
  filters: {
    query: '',
    showExpired: true
  },
  allAccounts: [],
  accountsGridRows: [],
  accountsRequestStatus: 'pending' // can be 'pending', 'error', 'success'
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SELECT_NEWSROOM:
      return {
        ...state,
        selectedNewsroomId: action.newsroomId,
        areAllNewsroomsSelected: action.areAllNewsroomsSelected
      };
    case CHANGE_PERMISSIONS_VIEW:
      return { ...state, isPermissionsView: action.isPermissionsView };
    case TOGGLE_SHOW_EXPIRED_ACCOUNTS:
      const filters = { ...state.filters, showExpired: !state.filters.showExpired };
      return { ...state, filters, accountsGridRows: calculateAccountsGridRows(state.allAccounts, filters) };
    case GET_ACCOUNTS_REQUEST:
      return { ...state, accountsRequestStatus: 'pending' };
    case GET_ACCOUNTS_FAILURE:
      return { ...state, accountsRequestStatus: 'error' };
    case GET_ACCOUNTS_SUCCESS:
      return reduceGetAccountsSuccess(state, action);
    case CHANGE_ACCOUNTS_SEARCH_QUERY: {
      const filters = { ...state.filters, query: action.query };
      return { ...state, filters, accountsGridRows: calculateAccountsGridRows(state.allAccounts, filters) };
    }
    default:
      return state;
  }
};

function reduceGetAccountsSuccess(state, action) {
  if (
    state.selectedNewsroomId === action.selectedNewsroomId &&
    state.areAllNewsroomsSelected === action.areAllNewsroomsSelected
  ) {
    const accountsWithNewsroom = action.payload.map(account => {
      return {
        ...account,
        expirationDate: account.expirationDate ? Date.parse(account.expirationDate) : null,
        identityDomain: extractDomain(account.user.identity),
        newsroom: account.newsroomId
      };
    });
    return {
      ...state,
      allAccounts: accountsWithNewsroom,
      accountsGridRows: calculateAccountsGridRows(accountsWithNewsroom, state.filters),
      accountsRequestStatus: 'success'
    };
  }
  // response for newsrooms which are no longer selected, ignore.
  // another response should arrive later
  return state;
}

function calculateAccountsGridRows(accounts, filters) {
  const now = new Date();
  const lowerCaseQuery = filters.query.toLowerCase();
  const showExpired = filters.showExpired;
  const isExpired = account => account.expirationDate && account.expirationDate < now;
  return accounts
    .filter(account => {
      const nameMatches = (account.user.name || '').toLowerCase().includes(lowerCaseQuery);
      const identityMatches = (account.user.identity || '').toLowerCase().includes(lowerCaseQuery);
      const userIdMatches = account.user.id === filters.query;
      const searchMatches = nameMatches || identityMatches || userIdMatches;
      const expirationMatches = showExpired ? true : !isExpired(account);
      return expirationMatches && searchMatches;
    })
    .map(account => {
      return {
        id: account.id,
        identityDomain: account.identityDomain,
        newsroom: account.newsroom,
        name: account.user.name,
        identity: account.user.identity,
        permissions: account.permissions,
        modifiedBy: account.modifiedBy || '⸺',
        addedBy: account.createdBy || '⸺',
        added: account.createdDate,
        expirationDate: account.expirationDate,
        lastLogin: account.lastLogin,
        isExpired: isExpired(account)
      };
    });
}
