import React from 'react';
import { withStyles } from '@mui/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useSelector } from 'react-redux';

import AccountsOverview from './AccountsOverview';
import LoginSelector from '../components/LoginSelector';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const appBarHeight = 64;
const appWidth = '100%';
const infoBackground = '#faf3d2';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: '#f0f0f0',
    fontFamily: 'Arial'
  },
  content: {
    marginTop: 0,
    zIndex: 1,
    display: 'flex',
    width: appWidth,
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
    background: '#FFF'
  },
  header: {
    position: 'absolute',
    width: appWidth,
    'background-color': '#2e4057'
  },
  main: {
    width: '100%',
    marginTop: appBarHeight + 15,
    overflow: 'auto',
    padding: '0px 20px',
    height: `calc(100vh - ${appBarHeight + 15}px)`,
    spacing: { unit: '10px' }
  },
  message: {
    padding: '4px',
    position: 'fixed',
    top: '-61px',
    left: 0,
    right: 0,
    background: infoBackground,
    borderBottom: '1px solid #f0db76',
    animation: 'message .7s .7s',
    'animation-fill-mode': 'forwards',
    '-webkit-animation': 'message .7s .7s',
    '-webkit-animation-fill-mode': 'forwards',
    '-moz-animation': 'message .7s .7s',
    '-moz-animation-fill-mode': 'forwards',
    '& p': {
      alignItems: 'center',
      padding: '0 20px',
      textAlign: 'left',
      fontSize: '1rem',
      lineHeight: '20px'
    }
  }
});

const customTheme = createTheme({
  shape: { spacing: '12px' },
  shadows: Array(25).fill('none'),
  palette: {
    primary: { main: '#186EF0' },
    secondary: { main: '#ffffff' },
    warning: { main: '#BF0E2E' }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        disableElevation: true
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          'margin-right': '20px'
        }
      }
    },
    MuiAppBar: {
      defaultProps: { elevation: 0 },
      styleOverrides: {
        root: {
          position: 'absolute'
        }
      }
    }
  }
});

const emotionCache = createCache({
  key: 'emotion',
  nonce: document.head.querySelector('[property=csp-nonce][content]').content
});

const App = props => {
  const config = useSelector(state => state.config);
  const { root: rootClass, ...parentClasses } = props.classes;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={customTheme}>
          <div className={rootClass}>
            <LoginSelector classes={parentClasses} />
            {config.isLoaded && <AccountsOverview classes={parentClasses} />}
            {config.isError && <span>Failed to load configuration </span>}
          </div>
        </ThemeProvider>
      </CacheProvider>
    </LocalizationProvider>
  );
};

export default withStyles(styles)(App);
