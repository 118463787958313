import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import Header from '../components/Header';
import AddNewsroomDialog from '../components/AddNewsroomDialog';
import NotificationsManager from '../components/util/NotificationsManager';
import Notifications from '../components/util/Notifications';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AccountsTable from './AccountsTable';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import {
  changeAccountsSearchQuery,
  changePermissionsView,
  toggleShowExpiredAccounts,
  initializeAccountsOverview,
  reloadAccounts,
  selectAllNewsrooms,
  selectNewsroom
} from '../actions/accountsOverview';
import InputAdornment from '@mui/material/InputAdornment';
import Clear from '@mui/icons-material/Clear';

import AccountDialog from '../components/AccountDialog';
import { reloadNewsrooms } from '../actions/newsroomsList';
import { ErrorOutline } from '@mui/icons-material';
import AuditLogsDialog from '../components/AuditLogsDialog';

const styles = theme => {
  return {
    content: {},
    header: {},
    main: {},
    message: {}
  };
};

class AccountsOverview extends Component {
  state = {
    newsroomDialog: { isOpened: false },
    accountDialog: { isOpened: false },
    auditLogsDialog: { isOpened: false },
    notificationsManager: new NotificationsManager()
  };

  openAddNewsroomDialog = () => {
    this.setState({ newsroomDialog: { isOpened: true } });
  };

  onShowExpiredChanged = () => {
    this.props.dispatch(toggleShowExpiredAccounts());
  };

  closeAddNewsroomDialog = dataWasChanged => {
    this.setState({ newsroomDialog: { isOpened: false } });
    if (dataWasChanged) {
      this.props.dispatch(reloadNewsrooms(this.props.isUserSystemAdmin));
    }
  };

  openAddAccountDialog = () => {
    const { selectedNewsroomId } = this.props.accountsOverview;
    this.setState({ accountDialog: { isOpened: true, newsroom: selectedNewsroomId } });
  };

  openEditAccountDialog = (accountId, newsroom) => {
    this.setState({ accountDialog: { isOpened: true, accountId, newsroom } });
  };

  openAuditLogsDialog = () => {
    const { selectedNewsroomId } = this.props.accountsOverview;
    this.setState({ auditLogsDialog: { isOpened: true, newsroom: selectedNewsroomId } });
  };

  closeAccountDialog = dataWasChanged => {
    this.setState({ accountDialog: { isOpened: false } });
    if (dataWasChanged) {
      this.props.dispatch(reloadAccounts());
    }
  };

  closeAuditLogsDialog = () => {
    this.setState({ auditLogsDialog: { isOpened: false } });
  };

  notify = notification => {
    this.state.notificationsManager.addNotification(notification);
    this.setState({ notificationsManager: this.state.notificationsManager });
  };

  removeNotification = notificationId => {
    this.state.notificationsManager.removeNotification(notificationId);
    this.setState({ notificationsManager: this.state.notificationsManager });
  };

  componentDidMount() {
    this.props.dispatch(initializeAccountsOverview());
  }

  onSearchQueryChange = query => {
    this.props.dispatch(changeAccountsSearchQuery(query));
  };

  render() {
    const { content, main, header } = this.props.classes;

    const searchQuery = this.props.accountsOverview.filters.query;
    return (
      <div className={content} style={{ minWidth: '800px' }}>
        <Notifications
          notifications={this.state.notificationsManager.notifications}
          onClose={this.removeNotification}
        />

        <Header className={header} addNewsroom={this.openAddNewsroomDialog} showAuditLog={this.openAuditLogsDialog} />

        <main className={main} style={{ width: '95%', marginRight: 'auto', marginLeft: 'auto' }}>
          <Box sx={{ display: 'flex' }}>
            <TextField
              label="Filter by name or identity"
              onChange={e => this.onSearchQueryChange(e.target.value)}
              sx={{ width: '250px', marginTop: '10px', marginRight: '10px', marginBottom: '10px' }}
              value={searchQuery}
              InputProps={{
                endAdornment: searchQuery && (
                  <InputAdornment position="end">
                    <Clear onClick={() => this.onSearchQueryChange('')} />
                  </InputAdornment>
                )
              }}
            />
            {this.renderNewsroomSelector()}
            {this.renderShowExpiredCheckBox()}
            <Box style={{ display: 'flex', marginLeft: 'auto', marginRight: '10px' }}>
              {this.renderTabSelector()}
              <Button
                onClick={() => this.openAddAccountDialog()}
                variant="contained"
                size="small"
                style={{ marginTop: '10px', marginBottom: '10px', marginLeft: 'auto' }}
              >
                Add New Account
              </Button>
            </Box>
          </Box>

          {this.renderAccounts()}
        </main>

        {this.renderDialogs()}
      </div>
    );
  }

  onNewsroomSelectorChange = (event, newsroom) => {
    if (newsroom) {
      localStorage.setItem('scrollPosition', 0);
      if (newsroom.isAllNewsrooms) {
        this.props.dispatch(selectAllNewsrooms());
      } else {
        this.props.dispatch(selectNewsroom(newsroom.id));
      }
    }
  };

  renderNewsroomSelector() {
    const newsrooms = this.props.newsroomsList.inOrder;

    const allNewsroomsOption = { name: 'All newsrooms', isAllNewsrooms: true };
    const allOptions = newsrooms.length > 0 ? [allNewsroomsOption, ...newsrooms] : newsrooms;
    const filterOptions = (options, state) => {
      const query = (state.inputValue || '').trim().toLowerCase();
      return options.filter(option => {
        return option.isAllNewsrooms || option.name.toLowerCase().includes(query);
      });
    };

    const { selectedNewsroomId, areAllNewsroomsSelected } = this.props.accountsOverview;
    const selectedNewsrooms = newsrooms.filter(newsroom => selectedNewsroomId === newsroom.id);
    let value = allNewsroomsOption;
    if (!areAllNewsroomsSelected) {
      value = selectedNewsrooms.length > 0 ? selectedNewsrooms[0] : null;
    }

    return (
      <Autocomplete
        value={value}
        disablePortal
        options={allOptions}
        filterOptions={filterOptions}
        style={{ marginTop: '10px' }}
        sx={{ width: 300 }}
        getOptionLabel={item => item.name}
        renderInput={params => <TextField {...params} label="Filter by newsroom" />}
        onChange={this.onNewsroomSelectorChange}
      />
    );
  }

  renderShowExpiredCheckBox() {
    return (
      <FormControlLabel
        control={<Checkbox checked={this.props.accountsOverview.filters.showExpired} />}
        sx={{ marginTop: '10px', marginLeft: '5px', marginBottom: '10px' }}
        label="Show expired accounts"
        onChange={this.onShowExpiredChanged}
      />
    );
  }

  onTabChange = (event, newValue) => {
    this.props.dispatch(changePermissionsView(newValue === 0));
  };

  renderTabSelector() {
    const { isPermissionsView } = this.props.accountsOverview;
    const tabValue = isPermissionsView ? 0 : 1;
    return (
      <Tabs
        value={tabValue}
        onChange={this.onTabChange}
        style={{ marginTop: '15px', marginBottom: '10px', marginLeft: 'auto', marginRight: '15px' }}
      >
        <Tab label="Permissions" />
        <Tab label="Details" />
      </Tabs>
    );
  }

  renderAccounts() {
    const status = this.props.accountsOverview.accountsRequestStatus;

    if (status === 'pending') {
      return (
        <Box style={{ marginTop: '50px' }}>
          <Grid container style={{ marginTop: '30px', alignContent: 'center', justifyContent: 'center' }}>
            <strong>Loading accounts...</strong>
          </Grid>
          <Grid>
            <LinearProgress
              sx={{
                '--LinearProgress-thickness': '12px'
              }}
              style={{ marginTop: '10px', width: '100%' }}
            />
          </Grid>
        </Box>
      );
    } else if (status === 'success') {
      return (
        <Box>
          <AccountsTable
            accountsOverview={this.props.accountsOverview}
            newsroomsByIds={this.props.newsroomsList.byId}
            editAccount={this.openEditAccountDialog}
          />
        </Box>
      );
    }

    return (
      <Box style={{ marginTop: '50px' }}>
        <Grid container style={{ marginTop: '30px', alignContent: 'center', justifyContent: 'center' }}>
          <ErrorOutline style={{ fontSize: 60 }} />
        </Grid>
        <Grid container style={{ marginTop: '30px', alignContent: 'center', justifyContent: 'center' }}>
          <strong> Failed to load accounts </strong>
        </Grid>
      </Box>
    );
  }

  renderDialogs = () => {
    return (
      <>
        {this.state.newsroomDialog.isOpened && (
          <AddNewsroomDialog onClose={this.closeAddNewsroomDialog} notify={this.notify} />
        )}

        {this.state.accountDialog.isOpened && (
          <AccountDialog
            onClose={this.closeAccountDialog}
            notify={this.notify}
            accountId={this.state.accountDialog.accountId}
            newsroom={this.state.accountDialog.newsroom}
          />
        )}

        {this.state.auditLogsDialog.isOpened && (
          <AuditLogsDialog
            onClose={this.closeAuditLogsDialog}
            newsroom={this.state.auditLogsDialog.newsroom}
            notify={this.notify}
          />
        )}
      </>
    );
  };
}

const mapStateToProps = state => {
  return {
    newsroomsList: state.newsroomsList,
    accountsOverview: state.accountsOverview,
    searchQuery: state.accountsOverview.filters.query,
    isUserSystemAdmin: state.session.isUserSystemAdmin
  };
};

export default compose(withStyles(styles), connect(mapStateToProps))(AccountsOverview);
