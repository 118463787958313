import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { fetchAuditLog } from '../requests';
import { useSelector } from 'react-redux';

// if given type has no name in this object - type will be displayed to user instead of name
// order of entry determines order in UI when sorting events with the same time
const eventTypeToName = {
  accountCreated: 'Account added',
  accountRemoved: 'Account removed',
  permissionsModified: 'Permissions modified',
  expirationModified: 'Expiration date modified'
};

function AuditLogsDialog({ newsroom, onClose, notify }) {
  const [auditLogs, setAuditLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  const newsroomsById = useSelector(state => state.newsroomsList.byId || {});
  const accessPermissions = useSelector(state => state.config.accessPermissions || []);
  const adminPermissions = useSelector(state => state.config.adminPermissions || []);

  const tableRef = useRef(null);
  const jwt = useSelector(state => state.session.jwt);

  useEffect(
    () => {
      const since = new Date();
      since.setMonth(since.getMonth() - 6); // last 6 months
      fetchAuditLog(jwt, newsroom, since)
        .then(logs => {
          setAuditLogs(changeLogsFormat(logs, newsroomsById, accessPermissions, adminPermissions));
          setIsLoading(false);
        })
        .catch(error => {
          console.error('Failed to load audit logs', error);
          setLoadingError('Failed to load audit logs');
        });
    },
    [newsroom]
  );

  let content;
  if (isLoading) {
    content = renderLoading();
  } else if (loadingError) {
    content = renderLoadingError();
  } else {
    content = renderTable(tableRef, auditLogs);
  }

  return (
    <Dialog open={true} fullWidth={true} maxWidth="xl" onClose={onClose} disableRestoreFocus>
      <DialogTitle>Audit log (for last 6 months)</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={() => copyWholeTableToClipboard(tableRef, notify)}>Copy all to clipboard</Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

function renderLoading() {
  return <span>loading</span>;
}

function renderLoadingError() {
  return <span>loading error</span>;
}

function renderTable(tableRef, logs) {
  return (
    <Table ref={tableRef} size="small" onCopy={copyTableFragmentToClipboard}>
      <TableHead>
        <TableRow>
          <TableCell key={'timestamp'}>Timestamp (UTC)</TableCell>
          <TableCell key={'account'}>Account</TableCell>
          <TableCell key={'newsroom'}>Newsroom</TableCell>
          <TableCell key={'action'}>Action</TableCell>
          <TableCell key={'oldValue'}>Old value</TableCell>
          <TableCell key={'newValue'}>New value</TableCell>
          <TableCell key={'actor'}>Actor</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{logs.map(renderLogRow)}</TableBody>
    </Table>
  );
}

function renderLogRow(log) {
  return (
    <TableRow key={log.id}>
      <TableCell key={log.id + '_timestamp'}>{log.formattedTime}</TableCell>
      <TableCell key={log.id + '_identity'}>{log.identity}</TableCell>
      <TableCell key={log.id + '_newsroom'}>{log.newsroom}</TableCell>
      <TableCell key={log.id + '_type'}>{log.typeLabel}</TableCell>
      <TableCell key={log.id + '_oldValue'}>{renderPermissions(log.oldValue)}</TableCell>
      <TableCell key={log.id + '_newValue'}>{renderPermissions(log.newValue)}</TableCell>
      <TableCell key={log.id + '_actor'}>{log.actor}</TableCell>
    </TableRow>
  );
}

function renderPermissions(permissions) {
  return permissions.map((permission, index) => {
    const isLastElement = index === permissions.length - 1;
    return (
      <span key={permission} style={{ display: 'block' }}>
        {permission + (isLastElement ? '' : ',')}
      </span>
    );
  });
}

function changeLogsFormat(logs, newsroomsById, accessPermissions, adminPermissions) {
  const newsroomLabel = newsroomId => {
    const newsroom = newsroomsById[newsroomId];
    return newsroom ? newsroom.name : newsroomId;
  };
  const accessPermissionsMap = Object.fromEntries(
    accessPermissions.map(permission => [permission.permission, 'Access ' + permission.label])
  );
  const adminPermissionsMap = Object.fromEntries(
    adminPermissions.map(permission => [permission.permission, permission.label])
  );
  const permissionsMap = { ...accessPermissionsMap, ...adminPermissionsMap };

  const permissionLabel = permission => {
    if (permission === 'auth:access') {
      // the permission was renamed but we store old value in audit log
      permission = 'account:admin';
    }
    return permissionsMap[permission] || permission;
  };

  const permissionsLabels = commaSeparatedPermissions => {
    return commaSeparatedPermissions
      .split(',')
      .map(permissionLabel)
      .sort();
  };

  const formatDate = date => {
    return date ? date.substring(0, 10) : '-';
  };

  const formatTime = time => {
    return time.substring(0, 19).replace('T', ' ');
  };

  const formatOldValue = logLine => {
    if (logLine.type === 'permissionsModified') {
      return permissionsLabels(logLine.oldValue);
    }
    if (logLine.type === 'accountRemoved' && logLine.actor === 'delete-inactive-accounts') {
      const account = JSON.parse(logLine.oldValue);
      return [
        'Removed due to no usage',
        'Last modified: ' + formatDate(account.modifiedDate),
        'Last login: ' + formatDate(account.lastLogin)
      ];
    }
    if (logLine.type === 'expirationModified') {
      return [formatTime(logLine.oldValue)];
    }
    return [];
  };

  const formatNewValue = logLine => {
    if (logLine.type === 'permissionsModified') {
      return permissionsLabels(logLine.newValue);
    }
    if (logLine.type === 'expirationModified') {
      return [formatTime(logLine.newValue)];
    }
    return [];
  };

  const eventTypeNamesArray = Object.keys(eventTypeToName);
  return logs
    .map(log => {
      return {
        ...log,
        formattedTime: formatTime(log.creationTime),
        typeLabel: eventTypeToName[log.type] || log.type,
        newsroom: newsroomLabel(log.newsroomId),
        oldValue: formatOldValue(log),
        newValue: formatNewValue(log)
      };
    })
    .sort((a, b) => {
      const dateOrder = Date.parse(b.creationTime) - Date.parse(a.creationTime);
      if (dateOrder === 0) {
        const eventNameA = eventTypeToName[a.type];
        const eventNameB = eventTypeToName[b.type];
        if (eventNameA && eventNameB) {
          return eventTypeNamesArray.indexOf(b.type) - eventTypeNamesArray.indexOf(a.type);
        }
        if (!eventNameA && !eventNameB) {
          return b.type.localeCompare(a.type);
        }
        return eventNameB ? -1 : 1;
      }
      return dateOrder;
    });
}

// we override default copy mechanism because we want to present permissions as a list of items in new lines
// but copy without newlines because it messes up the copy/paste format
function copyRowsToClipboard(rowsElements) {
  const tableText = rowsElements
    .map(row => {
      const cells = Array.from(row.querySelectorAll('td,th'));
      return cells
        .map(cell => {
          return cell.innerText.replace(/(\r\n|\n|\r)/gm, ''); // remove line breaks
        })
        .join('\t');
    })
    .join('\n');
  return navigator.clipboard.writeText(tableText);
}

function copyTableFragmentToClipboard(e) {
  const selectionRange = window.getSelection().getRangeAt(0);
  const clonedSelectionRange = selectionRange.cloneContents();

  if (clonedSelectionRange.childElementCount === 0) {
    return; // single element or part of it is selected, let's fall back to default behaviour (no preventDefault call)
  }

  const clonedSelectionRangeChildren = Array.from(clonedSelectionRange.children);

  let rows = clonedSelectionRangeChildren.flatMap(child => {
    if (child.tagName === 'TR') {
      return [child];
    }
    return Array.from(child.querySelectorAll('tr'));
  });

  if (rows.length === 0) {
    // element(s) within a single row were selected, so we get an element which contains only td elements
    // let's create a wrapper for it
    const row = document.createElement('tr');
    clonedSelectionRangeChildren.forEach(child => row.appendChild(child));
    rows = [row];
  }
  copyRowsToClipboard(rows);
  e.preventDefault();
}

function copyWholeTableToClipboard(tableRef, notify) {
  if (tableRef && tableRef.current) {
    const rows = Array.from(tableRef.current.querySelectorAll('tr'));
    copyRowsToClipboard(rows).then(
      () => notify({ message: 'Copied ' + (rows.length - 1) + ' events to clipboard', severity: 'success' }) // -1 for header
    );
  }
}

AuditLogsDialog.propTypes = {
  onClose: PropTypes.func // function that will stop rendering the dialog
};

export default AuditLogsDialog;
