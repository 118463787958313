import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert as MuiAlert, Snackbar } from '@mui/material';

class Alert extends Component {
  _isMounted = false;

  state = {
    open: !!this.props.message,
    message: this.props.message
  };

  close = () => {
    this.props.onCloseHandler();
    if (this._isMounted) {
      this.setState({ open: false });
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={this.state.open}
        autoHideDuration={this.props.autoHideDuration}
        onClose={this.close}
        onClick={this.close}
      >
        <MuiAlert onClose={this.close} severity={this.props.severity} variant="filled">
          {this.props.message}
        </MuiAlert>
      </Snackbar>
    );
  }
}

Alert.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
  onCloseHandler: PropTypes.func,
  autoHideDuration: PropTypes.number
};

Alert.defaultProps = {
  message: '',
  severity: 'info',
  onCloseHandler: e => e,
  autoHideDuration: 3000
};

export default Alert;
