import { combineReducers } from 'redux';
import config from './config';
import session from './session';
import accountsOverview from './accountsOverview';
import newsroom from './newsroom';
import newsroomsList from './newsroomsList';
import account from './account';

export default combineReducers({
  config,
  session,
  accountsOverview,
  newsroom,
  newsroomsList,
  account
});
